/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family:
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9em;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #555;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    float: left;
    margin: 0 10px 30px 0;
  }
  img {
    margin-bottom: 5px;
  }
}

/* --------- asc styles --------- */
#header {
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 720px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      max-width: 90px;
      height: auto;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.3em;
      font-weight: 500;
      color: #555;
      line-height: 1.1em;
      vertical-align: middle;
    }
    span.big {
      color: #333;
      font-weight: 600;
      font-family:
        'Open Sans',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
      .com {
        font-size: 0.8em;
      }
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: 'Open Sans', Verdana;
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}

#phones-wrap {
  padding: 3px 10px;
  background: $theme-primary;
  padding: 3px 0;
  font-size: 0.9em;
  font-weight: 600;
}

#header-wrapper {
  position: relative;
  background: #333 url(../images/header-bg-small.jpg) no-repeat 50% top;
  background-size: cover;
  &.cert {
    background: #333 url(../images/header-bg-cert-small.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.corp {
    background: #333 url(../images/header-bg-corp-small.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.hazwoper {
    background: #333 url(../images/header-bg-hazwoper-small.jpg) no-repeat 50% top;
    background-size: cover;
  }
  &.msha {
    background: #333 url(../images/header-bg-msha-small.jpg) no-repeat 50% top;
    background-size: cover;
  }
  &.osha {
    background: #333 url(../images/header-bg-osha-small.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.state {
    background: #333 url(../images/header-bg-state-small.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.inner {
    background: #fff;
  }
}

#header-wrap {
  background: rgba(255, 255, 255, 0.85);
  #header {
    .select-state {
      display: none;
      clear: right;
      float: right;
      width: 100%;
      max-width: 300px;
      ul.drop {
        z-index: 100;
      }
    }
  }
}

#banner-wrap {
  border-top: 5px solid #f8d020;
  border-bottom: 5px solid #f8d020;
  #banner h2 {
    font-family: var(--font-family);
  }
}

#course-wrap {
  background: #f5f5f5;
  .box {
    .learn-more a {
      color: #555;
    }
    .price .from {
      font-size: 18px;
      margin-bottom: 5px;
    }
  }
}

.box,
.card {
  &.blue {
    .card-header {
      background: $theme-secondary;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
      a {
        color: #fff;
      }
    }
  }
  &.grey {
    background-color: #f9f9f9;
  }
  &.blue-bg {
    background: $theme-secondary;
  }
}
.blue-border {
  border-top: 3px solid $theme-secondary;
}

.card.course {
  background: #f2f2f2;
  border: 1px solid $theme-secondary;
  &.small-blue {
    background: #e4f1ff;
    border-color: #b8daff;
    font-weight: 600;
  }
}
.collapse-container .collapse-toggle:before {
  color: $theme-secondary;
}

.action {
  .price {
    margin: 10px auto;
  }
  .cta-btn {
    margin-top: -45px;
    max-width: 175px;
  }
}

a.contact-icon {
  border: 0;
  color: #888;
  font-weight: 600;
  i {
    font-size: 1.2em;
    border: 2px solid #ccc;
  }
  &:hover {
    color: inherit;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      height: auto;
      margin-right: 10px;
    }
    span.big {
      display: inline-block;
      font-size: 1.5em;
    }
    span.small {
      display: inline-block;
    }
  }
  .course-catalog .searchbar {
    input.search {
      width: 60%;
    }
    .right {
      float: right;
      position: absolute;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 70%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    img {
      height: auto;
    }
  }
  #header-wrapper {
    position: relative;
    background: #333 url(../images/header-bg.jpg) no-repeat 50% top;
    background-size: cover;
    &.cert {
      background: #333 url(../images/header-bg-cert.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    &.corp {
      background: #333 url(../images/header-bg-corp.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    &.hazwoper {
      background: #333 url(../images/header-bg-hazwoper.jpg) no-repeat 50% top;
      background-size: cover;
    }
    &.msha {
      background: #333 url(../images/header-bg-msha.jpg) no-repeat 50% top;
      background-size: cover;
    }
    &.osha {
      background: #333 url(../images/header-bg-osha.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    &.state {
      background: #333 url(../images/header-bg-state.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
  }
}

// Extra large devices (large desktops, 1080px and up)
@media (min-width: 1080px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header-wrap {
    #header {
      #support {
        display: block;
      }
      .select-state {
        display: block;
      }
    }
  }
  #header a.logo {
    margin-top: 20px;
    span.big {
      font-size: 1.8em;
      font-weight: 800;
    }
  }
  #course-wrap {
    .box {
      p.description {
        min-height: 135px;
      }
      &.certificate {
        p.description {
          min-height: 110px;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1201px) {
}
